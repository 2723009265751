import { useState, useEffect } from 'react'
//import axios from 'axios'
import personService from './services/persons'


const Filter = (props) => {
  return (
    <div>
    Filter: <input id="search"  onChange={props.handleSearch} value={props.query}/>
  </div>
  )
}
const Notification = (props) => {
    if (props.notification === null) {
      return null
    }
    const notificationClass = props.notification.error !== 'success' 
    ? 'error' 
    : 'success';

  return (
    <div className={`notification ${notificationClass}`}>
      {props.notification.message}
    </div>
  );
  }
const PersonForm = (props) => {
  return (
    <div>
    <form>
        <div>
          name: <input id="name" value={props.newPerson.name} onChange={props.handleChange}/>
        </div>

        <div>
          number: <input id="number" value={props.newPerson.number} onChange={props.handleChange}/>
        </div>

        
        <div>
          
        </div>
      </form>
    
        <button text='submit' onClick={() => {props.addPerson()}}>Add</button>
      </div>

      
  )
}
const Persons = (props) => {

  let searchResult = props.searchResult

  return (

    searchResult.map((p) => (
      <div className='people' key={p.id}>
        {p.name + ' ' + p.number + ' '} 
        <button onClick={() => {props.deletePerson(p.id)}}>Delete </button>
      </div> 

      ))

  
)
}

const App = () => {

  useEffect(() => {
    personService
      .getAll()
      .then(initialPersons => {
        setPersons(initialPersons);
      })
      .catch(error => {
        setNotification({
          message: 'Did Not Get Persons',
          error: 'error',
        });
        setTimeout(() => {
          setNotification(null);
        }, 5000);
      });
  }, []);


  // STATES

  const [persons, setPersons] = useState([])

  const [newPerson, setNewPerson] = useState(
    {
      id: '',
      name: '',
      number: ''
    }

  )
  const [keyword, setKeyword] = useState('')

  const [notification, setNotification] = useState(null)

  const handleSearch = (e) => {
    setKeyword(e.target.value)
  }

  const handleChange = (e) => {
    
    const person = {
        id: String(persons.length + 1),
        name: newPerson.name,
        number: newPerson.number
    }

    person[e.target.id] = e.target.value

    setNewPerson(person)

  }

  const defaultPerson = () => {

    setNewPerson(
      {
        id: '',
        name: '',
        number: ''
      }

    )

  }

 const addPerson = () => {
    
  const personObject = {
    name: newPerson.name,
    number: newPerson.number,
  }

  const names = persons.map(item => item.name)

  const idx = names.indexOf(newPerson.name)

  if (idx >= 0) {

    const personId = persons[idx]['id']

    if (window.confirm(`${newPerson.name} is already added to phonebook, would you like to update number?`)) {

      // get the ID in the array
      const result = updatePerson(personId, personObject)
      console.log('confirmed')
      setNotification(
        {
          message: result,
          error: 'success'
        })

      
    }
  }

    else
    // update with new person
    {
      personService
      .create(personObject)
      .then(returnedPerson => {
        setPersons(persons.concat(returnedPerson))
        setNotification(
          {
            message: `Added ${personObject.name}`,
            error: 'success'
          })
          setTimeout(() => {
            setNotification(null)
          }, 5000)
          defaultPerson()
        })
        .catch(error => {
          // this is the way to access the error message
          console.log("hello ", error.response.data.message)
          setNotification(error.response.data)
          setTimeout(() => {
              setNotification(null)
            }, 5000)
            defaultPerson()

          
        })
    }
  }

  const getPersons = () => {
    return personService
      .getAll()
      .then(response => setPersons(response))  // Ensure this returns a promise
      .catch(error => {
        console.error("Error fetching people:", error);  // Log any errors
        throw error;  // Rethrow the error so it's caught in `updatePerson`
      });
  };


  const updatePerson = (id, personObject) => {
      personService
      .update(id, personObject)
      .then(response => {
        console.log("Person Updated", response)
        return getPersons();
      })

      .catch(error => {
        setNotification(
          {
            message: `${personObject.name} does not exist`,
            error: 'error'
          })
        console.log(error)
        setTimeout(() => {
          setNotification(null)
        }, 5000)
        defaultPerson()
        return
      })
      defaultPerson()
      return (`Updated ${personObject.name}`)
  }


  const deletePerson = (id) => {
    {
      if (window.confirm('Are you sure?')) {

        personService
        .remove(id)
        .then(removedPerson => {
          console.log(removedPerson)
          setPersons(persons.filter(item => item.id !== id))
          console.log('Deleted ' + id)
          setNotification(
            {
              message: `Successfully deleted ${id}`,
              error: 'success'
            })
    
        })
        .catch(error => {
          setNotification(
            {
              message: `Did not delete ${id}`,
              error: 'error'
            })
        })

        setTimeout(() => {
          setNotification(null)
        }, 5000)

      } 
      
      else {
        console.log('Did Not Delete ' + id)

      }

    }
    defaultPerson()
    }
  






   /* 
    const personObject = {
      id: newPerson.id,
      name: newPerson.name,
      number: newPerson.number,
    }

    const names = persons.map(item => item.name)

    if (names.includes(newPerson.name)) {
      alert(`${newPerson.name} is already added to phonebook`)
    }
    else
    // update with new person
    {
      personService
      .create(personObject)
      .then(returnedPerson => {
        setPersons(persons.concat(returnedPerson))
      })
      
    }

    defaultPerson()
  */
    

  
  

  // FILTER FUNCTION FOR SEARCH

  //search keyword from names array by name
  //  
  var searchResult = persons.filter(person => person.name.toLowerCase().indexOf(keyword.toLowerCase()) > -1);



  return (
    <div>

      <h3>Phonebook</h3>


      <Notification notification={notification}/>

      <Filter query={keyword} handleSearch={handleSearch}/>

      <h3>Add New</h3>

      <PersonForm newPerson={newPerson} deletePerson={deletePerson} defaultPerson={defaultPerson} addPerson={addPerson} handleChange={handleChange}/>

      <h3>Numbers</h3>

      <Persons searchResult={searchResult} persons={persons} deletePerson={deletePerson}  updatePerson={updatePerson}/>

    </div>


  )
}



export default App